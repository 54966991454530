
import { defineComponent } from "vue";

import TeamAvatar from "@/components/TeamAvatar.vue";

export default defineComponent({
  components: {
    TeamAvatar,
  },
  setup() {
    return {};
  },
});
