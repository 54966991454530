
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    avatar: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const avatarClass = computed(() => ({
      avatar: true,
      staff: true,
      [props.avatar]: true,
    }));

    return {
      avatarClass,
    };
  },
});
