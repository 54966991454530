import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b99347c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-profile" }
const _hoisted_2 = { class: "caption" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.avatarClass)
    }, null, 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "fullname", {}, undefined, true)
      ]),
      _createElementVNode("p", _hoisted_4, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true)
      ])
    ])
  ]))
}